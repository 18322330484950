import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'readMoreLink']

  initialize () {
    if(this.contentTarget.scrollHeight > this.contentTarget.clientHeight) {
      this.readMoreLinkTarget.classList.remove('hidden')
    }
  }

  expand (event) {
    event.preventDefault()

    this.contentTarget.classList.remove('max-h-96')
    this.readMoreLinkTarget.classList.add('hidden')
  }
}
