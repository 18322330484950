import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { profileId: String }

  open (event) {
    event.preventDefault()
    open(event.target.href, 'message', 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600')
  }
}
