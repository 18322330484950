import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { baseUrl: String }

  navigate (event) {
    event.preventDefault()

    if(event.target.value == '') return
    Turbolinks.visit(`${this.baseUrlValue}${event.target.value}`)
  }
}
