import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { variant: String }

  static targets = [
    'artistImage',
    'artistTitle',
    'artistUrl',
    'audioPlayer',
    'audioSource',
    'controls',
    'currentTime',
    'listingTrackOverlay', // only on the default variant
    'listingTrackOverlayPauseIcon', // only on the default variant
    'listingTrackOverlayPlayIcon', // only on the default variant
    'playAll', // only on the default variant
    'pauseAll', // only on the default variant
    'playAllIcon', // only on the default variant
    'pauseAllIcon', // only on the default variant
    'pauseIcon',
    'playButton',
    'playIcon',
    'profileTrackEntry', // only on the profile variant
    'profileTrackEntryPauseIcon', // only on the profile variant
    'profileTrackEntryPlayIcon', // only on the profile variant
    'progress',
    'totalTime',
    'track',
    'trackTitle'
  ]

  initialize() {
    this._currentTrackIndex = 0
    this._currentTrackDuration = 0
    // 0 stopped, 1 paused, 2 playing
    this._playState = 0
    this._variant = this.variantValue ? this.variantValue : 'default'
  }

  connect() {
    this.audioPlayerTarget.addEventListener('loadedmetadata', this.updateMetadata.bind(this))
    this.audioPlayerTarget.addEventListener('timeupdate', this.updateTime.bind(this))
    this.audioPlayerTarget.addEventListener('play', this.handlePlay.bind(this))
    this.audioPlayerTarget.addEventListener('pause', this.handlePause.bind(this))
    this.audioPlayerTarget.addEventListener('ended', this.handleTrackEnd.bind(this))
  }

  disconnect() {
    this.audioPlayerTarget.removeEventListener('loadedmetadata', this.updateMetadata.bind(this))
    this.audioPlayerTarget.removeEventListener('timeupdate', this.updateTime.bind(this))
    this.audioPlayerTarget.removeEventListener('play', this.handlePlay.bind(this))
    this.audioPlayerTarget.removeEventListener('pause', this.handlePause.bind(this))
    this.audioPlayerTarget.removeEventListener('ended', this.handleTrackEnd.bind(this))
  }

  changeTrackAndPlay(trackIndex) {
    if(this._playState != 0) this.updateTrackEntry(true, this._playState == 2)

    this._playState = 0
    this._currentTrackIndex = trackIndex
    this._currentTrack = this.trackTargets[this._currentTrackIndex].dataset
    this.resetControls()
    this.play()
  }

  currentTrack () {
    return this.trackTargets[this._currentTrackIndex].dataset
  }

  formatTime (totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60)
    let seconds = totalSeconds % 60

    if(seconds < 10) {
      seconds = `0${seconds}`
    }

    return `${minutes}:${seconds}`
  }

  handlePlay (event) {
    if(this._playState == 2) {
      return
    }

    // Played from external source
    this.updatePlayer(2)
  }

  handlePause (event) {
    if(this._playState == 1) {
      return
    }

    // Paused from external source
    this.updatePlayer(1)
  }

  handleTrackEnd () {
    this.skipToNextTrack()
  }

  nextTrack (event) {
    event.preventDefault()

    this.skipToNextTrack()
  }

  pause () {
    this.audioPlayerTarget.pause()
    this.updatePlayer(1)
  }

  play () {
    if(this._playState == 1) {
      return this.audioPlayerTarget.play()
    }

    this.updatePlayer(2)
    this.audioPlayerTarget.setAttribute('src', this.currentTrack().trackUrl)
    this.audioPlayerTarget.load()
    this.audioPlayerTarget.play()
    .then(() => {})
    .catch(err => {})
  }

  playPause (event) {
    if(event) {
      event.preventDefault()
    }

    if(this._playState == 2) {
      return this.pause()
    }

    this.play()
  }

  playTrack(event) {
    event.preventDefault()

    const trackIndex = parseInt(event.target.dataset.trackIndex)

    if(this._currentTrackIndex != trackIndex) {
      return this.changeTrackAndPlay(trackIndex)
    }

    this.playPause()
  }

  previousTrack (event) {
    event.preventDefault()

    let newTrackIndex = this._currentTrackIndex - 1
    if(newTrackIndex < 0) newTrackIndex = this.trackTargets.length - 1

    this.changeTrackAndPlay(newTrackIndex)
  }

  resetControls () {
    this.updateCurrentTime(0)
    this.updateDuration(0)
    this.updateProgress(0)
  }

  showControls() {
    this.controlsTarget.classList.remove('hidden')
  }

  skipToNextTrack () {
    let newTrackIndex = this._currentTrackIndex + 1
    if(newTrackIndex > this.trackTargets.length - 1) newTrackIndex = 0

    this.changeTrackAndPlay(newTrackIndex)
  }

  toggleCurrentListingTrackOverlay () {
    this.listingTrackOverlayTargets[this._currentTrackIndex].classList.toggle('invisible')
  }

  toggleCurrentListingTrackOverlayIcon () {
    this.listingTrackOverlayPlayIconTargets[this._currentTrackIndex].classList.toggle('hidden')
    this.listingTrackOverlayPauseIconTargets[this._currentTrackIndex].classList.toggle('hidden')
  }

  toggleCurrentProfileTrackEntry () {
    this.profileTrackEntryTargets[this._currentTrackIndex].classList.toggle('bg-red')
    this.profileTrackEntryTargets[this._currentTrackIndex].classList.toggle('text-white')
  }

  toggleCurrentProfileTrackEntryIcon () {
    this.profileTrackEntryPlayIconTargets[this._currentTrackIndex].classList.toggle('hidden')
    this.profileTrackEntryPauseIconTargets[this._currentTrackIndex].classList.toggle('hidden')
  }

  togglePlayAllButton () {
    if(this._variant != 'default') {
      return
    }

    if(this._playState < 2) {
      this.pauseAllIconTarget.classList.add('hidden')
      this.playAllTarget.innerText = 'Play all'
      this.playAllIconTarget.classList.remove('hidden')
    } else {
      this.playAllIconTarget.classList.add('hidden')
      this.playAllTarget.innerText = 'Pause'
      this.pauseAllIconTarget.classList.remove('hidden')
    }
  }

  togglePlayerPlayButton () {
    if(this._playState < 2) {
      this.pauseIconTarget.classList.add('hidden')
      this.playIconTarget.classList.remove('hidden')
    } else {
      this.playIconTarget.classList.add('hidden')
      this.pauseIconTarget.classList.remove('hidden')
    }
  }

  updateControlsTrackInfo () {
    this.trackTitleTarget.innerText = this.currentTrack().trackName
    this.artistTitleTarget.innerText = this.currentTrack().artistName
    this.artistImageTarget.setAttribute('src', this.currentTrack().artistImage)

    if(this._variant == 'default') {
      this.artistUrlTarget.setAttribute('href', this.currentTrack().artistUrl)
    }
  }

  updateCurrentTime (currentTime) {
    this.currentTimeTarget.innerText = this.formatTime(currentTime)
  }

  updateDuration (totalDuration) {
    this.totalTimeTarget.innerText = this.formatTime(totalDuration)
  }

  updateMetadata (event) {
    this._currentTrackDuration = Math.round(this.audioPlayerTarget.duration)
    this.updateDuration(this._currentTrackDuration)
  }

  updatePlayer (playState) {
    const previousPlayState = this._playState
    this._playState = playState
    this.togglePlayerPlayButton()
    this.togglePlayAllButton()
    if(previousPlayState == 0 && playState > 0) {
      this.showControls()
    }
    this.updateControlsTrackInfo()
    this.updateTrackEntry(previousPlayState == 0 && playState == 2, true)
  }

  updateProgress (currentTime) {
    if(currentTime == 0) {
      return this.progressTarget.value = 0
    }

    this.progressTarget.value = Math.round((parseInt(currentTime) / parseInt(this._currentTrackDuration)) * 100)
  }

  updateTime (event) {
    const currentTime = Math.round(this.audioPlayerTarget.currentTime)
    this.updateCurrentTime(currentTime)
    this.updateProgress(currentTime)
  }

  updateTrackEntry (trackEntry, trackIcon) {
    if(this._variant == 'default') {
      if(trackEntry) this.toggleCurrentListingTrackOverlay()
      if(trackIcon) this.toggleCurrentListingTrackOverlayIcon()
    } else {
      if(trackEntry) this.toggleCurrentProfileTrackEntry()
      if(trackIcon) this.toggleCurrentProfileTrackEntryIcon()
    }
  }
}
