import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['video']

  initialize () {
    this._currentVideo = null
  }

  playVideo (event) {
    event.preventDefault()

    const id = event.target.dataset.id
    if(id == this._currentVideo) return
    this._currentVideo = id
    fetch(`/videos/${id}`)
    .then(response => response.text())
    .then(html => {
      this.videoTarget.innerHTML = html
      this.videoTarget.scrollIntoView()
    })

    // TODO: Update icons
  }
}
