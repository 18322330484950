import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['closeIcon', 'menu', 'openIcon']

  toggle (event) {
    event.preventDefault()

    this.closeIconTarget.classList.toggle('hidden')
    this.menuTarget.classList.toggle('hidden')
    this.openIconTarget.classList.toggle('hidden')
  }
}
