import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { url: String }

  navigate (event) {
    event.preventDefault()
    Turbolinks.visit(this.urlValue.replace('REPLACE', event.target.value))
  }
}
