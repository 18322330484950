import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['genres', 'input', 'results', 'searchLink', 'tag', 'tagsLink']
  static values = {
    param: String,
    path: String,
    tagsPath: String
  }

  initialize () {
    this._activeMenu = 'genres'
    this._menuOpen = false
    this._toggleClass = 'sm:hidden'
  }

  handleBlur () {
    setTimeout(this.hideMenu.bind(this), 150)
  }

  handleKeyUp (event) {
    const value = event.target.value

    if(value.length > 1 && this._activeMenu == 'genres') {
      this.toggleResults()
    } else if(value.length < 2 && this._activeMenu == 'results') {
      this.toggleResults()
    }

    this.searchLinkTarget.href = `${this.pathValue}?${this.paramValue}=${value}`
    this.tagTarget.innerText = value
    this.tagsLinkTarget.href = `${this.tagsPathValue}?${this.paramValue}=${value}`
  }

  handleSearch (event) {
    if(event.target.value == '' && this._activeMenu == 'results') {
      this.toggleResults()
    }
  }

  showMenu (event) {
    if(this._menuOpen != true) {
      this._menuOpen = true
    }

    if(this.inputTarget.value.length < 2) {
      return this.showGenres()
    }

    this.showResults()
  }

  showGenres () {
    this.resultsTarget.classList.add(this._toggleClass)
    this._activeMenu = 'genres'
    this.genresTarget.classList.remove(this._toggleClass)
  }

  showResults () {
    this.genresTarget.classList.add(this._toggleClass)
    this._activeMenu = 'results'
    this.resultsTarget.classList.remove(this._toggleClass)
  }

  hideMenu () {
    if(this._menuOpen == true) {
      this._menuOpen = false
    }

    this.genresTarget.classList.add(this._toggleClass)
    this.resultsTarget.classList.add(this._toggleClass)
  }

  toggleResults () {
    if(this._activeMenu == 'results') {
      this.showGenres()
    } else {
      this.showResults()
    }
  }
}
